import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import config from "../Configuration/APIConfig";
import axios from "axios";
import { handleSuccess, handleError } from "../Layout/CustomAlerts";
import { useNavigate, Link } from "react-router-dom";
import Agenttab from "./AgentTab";
import Header from "../Layout/Header";
import moment from "moment";
import APIConfig from "../Configuration/APIConfig";
const initialFieldValues = {
    agentWalletId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    amount: 0,
    available: 0,
    type: "CREDIT",
    purpose: "TopUp",
    source: "PaymentGateway"
};
export default function Wallet() {
    const [agentWallets, setAgentWallets] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [availableAmount, setAvailableAmount] = useState(0);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.amount = values.amount === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            displayRazorpay();
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.UPDATEWALLET, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .create(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Created");
                    GetAgentWallets();
                    setBtnSubmit(true);
                    setValues(initialFieldValues)
                }
                else {
                    handleError(res.data.message);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const GetAgentWallets = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETAGENTWALLETBYAGENTS + "?userId=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded !== false) {
                    setAgentWallets(response.data.response.data);
                    const myList = response.data.response.data;
                    const firstRecord = myList.length > 0 ? myList[myList.length - 1] : null;
                    if (firstRecord != null) {
                        setAvailableAmount(firstRecord.available);
                    }
                }
            });
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEAGENTCREDIT + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetAgentWallets();
                    }
                    else {
                        handleError(response.data.data);
                        GetAgentWallets();
                    }
                });
        }
    }
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetAgentWallets();
    }, [])
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        const result = await axios.post(
            APIConfig.APIACTIVATEURL + APIConfig.PAYMENTREQUESTORDER + "?Amount=" + Math.round(values.amount) + "&PayRequest=RAZORPAY"
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        // Getting the order details back
        if (result.data.response.succeeded) {
            const { amount, id: order_id, currency, razorpayKey, name } = result.data.response.data;
            const options = {
                key: razorpayKey, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: name,
                description: "Top Up",
                order_id: order_id,
                handler: async function (response) {
                    const formData = new FormData()
                    formData.append("agentWalletId", values.agentWalletId)
                    formData.append("userId", localStorage.getItem('userId'))
                    formData.append("amount", values.amount)
                    formData.append("available", values.available)
                    formData.append("type", "CREDIT")
                    formData.append("purpose", values.purpose)
                    formData.append("source", values.source)
                    formData.append("referenceId", response.razorpay_payment_id)
                    formData.append("transactionDate", moment(new Date).format('YYYY-MM-DD'))
                    addOrEdit(formData);
                },
                prefill: {
                    name: APIConfig.COMPANYNAME,
                    email: APIConfig.COMPANYEMAIL,
                    contact: APIConfig.COMAPNYPHONE,
                },
                notes: {
                    address: APIConfig.COMPANYCITY,
                },
                theme: {
                    color: "#61dafb",
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Payment failed. Please try again");
        }
    }
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Wallet</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-between gx-xl-4 gray-simple">
                    <div className="col-xl-4 col-lg-8 col-md-12">
                        <div className="alert alert-primary">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="agentId" className="form-label">Wallet Balance : {availableAmount} INR</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Amount</label>
                                            <input type="text" value={values.amount} name="amount" onChange={handleInputChange} className={"form-control" + applyErrorClass('amount')} placeholder="0" />
                                            {errors.amount === false ? (<div className="validationerror">Please enter amount </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            <button type="submit" className="btn btn-primary">Top Up</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card card-height-100">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Wallet Transactions</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Id</th>
                                                <th>Amount</th>
                                                <th>Available Amount</th>
                                                <th>Type</th>
                                                <th>Source</th>
                                                <th>Purpose</th>
                                                <th>Transaction Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {agentWallets.length > 0 && agentWallets.map((v, index) =>
                                                <tr key={v.agentWalletId}>
                                                    <td>{index + 1}</td>
                                                    <td>{v.amount}</td>
                                                    <td>{v.available}</td>
                                                    <td>{v.type}</td>
                                                    <td>{v.source}</td>
                                                    <td>{v.purpose}</td>
                                                    <td>{moment.utc(v.transactionDate).local().format('DD MMM YYYY hh:mm a')}</td>
                                                    <td>
                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                <Link className="remove-item-btn" onClick={e => onDelete(e, v.agentCreditId)}>
                                                                    <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}