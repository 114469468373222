import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/HeaderHome";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import APIConfig from "../Configuration/APIConfig";
const initialFieldValues = {
    email: "",
    domainUrl: ""
};
export default function ForgetPassword() {
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [btnSubmit, setBtnSubmit] = useState(true);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const validate = () => {
        let temp = {};
        temp.email = values.email === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {        
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false)
            const formData = new FormData()
            formData.append("email", values.email)
            formData.append("domainUrl", window.location.origin)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            post: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.SENDRESETPASSWORD, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .post(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    setSuccessStatus(res.data.response.message);
                    setBtnSubmit(true);
                    setValues('');
                    setErrorStatus('')
                }
                else {
                    setBtnSubmit(true);
                    setErrorStatus(res.data.response.message);   
                    setSuccessStatus('');                 
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        <div className="row justify-content-center align-items-center m-auto">
                            <div className="col-12">
                                <div className="bg-mode shadow rounded-3 overflow-hidden">
                                    <div className="row g-0">
                                        {/* Vector Image */}
                                        <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                            <div className="p-3 p-lg-5">
                                                <img src="/assets/img/forgot.gif" className="img-fluid" alt />
                                            </div>
                                            {/* Divider */}
                                            <div className="vr opacity-1 d-none d-lg-block" />
                                        </div>
                                        {/* Information */}
                                        <div className="col-lg-6 order-1">
                                            <div className="p-4 p-sm-7">
                                                <h1 className="mb-2 fs-2">Forgot Password?</h1>
                                                {successStatus !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {successStatus}</div> : ""}
                                                {errorStatus !== "" ? <div class="alert alert-danger shadow-md"> <i class="fas fa-check-circle"></i> {errorStatus} </div> : ""}
                                                <p className="mb-0">Enter the email address associated with an account.</p>
                                                {/* Form START */}
                                                <form onSubmit={handleSubmit} className="mt-4 text-start" autoComplete="off" noValidate>
                                                    <div className="form py-4">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={values.email} onChange={handleInputChange} placeholder="Enter Email" />
                                                            {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                                        </div>
                                                        <div className="form-group">
                                                            {btnSubmit === true ? (
                                                                <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Reset Password</button>
                                                            ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* Form END */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}