import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../Configuration/APIConfig';
import axios from "axios";
export default function Header() {
    const navigate = useNavigate();
    const [agentWallet,setAgentWallet]=useState(0);
    const [agentCredit,setAgentCredit]=useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else if (localStorage.getItem('roleName') === null) {
            navigate("/");
        }
        else if (localStorage.getItem('roleName') !== "AGENT") {
            navigate("/");
        }
        else{
            GetAgentBalance();
        }
    }, [])
    const GetAgentBalance = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETAGENTBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setAgentWallet(response.data.response.data.walletBalance);
                    setAgentCredit(response.data.response.data.availableCredit);
                }
            });
    };
    return (
        <div className="header header-light">
            <div className="container">
                <nav id="navigation" className="navigation navigation-landscape">
                    <div className="nav-header">
                        <Link className="nav-brand static-show" href="#"><img src="/assets/img/logo.png" className="logo" alt="logo" style={{maxWidth:"160px", maxHeight:"60px"}} /></Link>
                        <Link className="nav-brand mob-show" href="#"><img src="/assets/img/logo.png" className="logo" alt="logo" style={{maxWidth:"160px", maxHeight:"60px"}} /></Link>
                        <div className="nav-toggle" />
                        <div className="mobile_nav">
                            <ul>
                                <li>
                                    <Link to={"/agentlogin"} className="bg-light-primary text-primary rounded"><i className="fa-regular fa-circle-user fs-6" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-menus-wrapper" style={{ transitionProperty: 'none' }}>
                        <ul className="nav-menu">
                            <li><Link to={"/searchflights"}>Home<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/searchflights"}><i class="fa-solid fa-plane-departure me-2"></i>Flights<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/searchhotels"}><i class="fa-solid fa-building me-2"></i>Hotels<span className="submenu-indicator" /></Link></li>                           
                            <li><Link to={"/bankaccounts"}><i class="fa-solid fa-building me-2"></i>Bank Details<span className="submenu-indicator" /></Link></li>
                        </ul>
                        {localStorage.getItem("roleName") === "AGENT" ?
                            <ul className="nav-menu nav-menu-social align-to-right">
                                <li className="me-1"><Link onClick={()=>GetAgentBalance()}>Wallet Balance : <b>{Math.round(agentWallet)} INR</b> Credit Balance : <b>{Math.round(agentCredit)} INR</b><i class="fa-solid fa-refresh m-2"></i></Link></li>
                                <li className="list-buttons me-2">
                                    <Link to={"/agent-profile"} className="bg-success"><i className="fa-regular fa-circle-user fs-6 me-2" />My Account</Link>
                                </li>
                            </ul> :
                            <ul className="nav-menu nav-menu-social align-to-right">
                                <li className="list-buttons me-2">
                                    <Link to={"/agentlogin"} className="bg-success"><i className="fa-regular fa-circle-user fs-6 me-2" />Agent Login</Link>
                                </li>
                            </ul>}
                    </div>
                </nav>
            </div>
        </div>
    );
}