import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function HeaderHome(props) {
    const navigate = useNavigate();
    return (
        <div className="header header-light">
            <div className="container">
                <nav id="navigation" className="navigation navigation-landscape">
                    <div className="nav-header">
                        <Link to={"/"} className="nav-brand" href="#"><img src="/assets/img/logo.png" className="logo" alt="logo" style={{maxWidth:"160px", maxHeight:"60px"}} /></Link>
                        <Link to={"/"} className="nav-brand mob-show" href="#"><img src="/assets/img/logo.png" className="logo" alt="logo" style={{maxWidth:"160px", maxHeight:"60px"}} /></Link>
                        <div className="nav-toggle" />
                        <div className="mobile_nav">
                            <ul>
                                <li>
                                    <Link to={"/agentlogin"} className="bg-light-primary text-primary rounded"><i className="fa-regular fa-circle-user fs-6" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-menus-wrapper" style={{ transitionProperty: 'none' }}>
                        <ul className="nav-menu">
                            <li><Link to={"/"}>Home<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/about-us"}>About us<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/terms"}>Terms and Conditions<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/faq"}>FAQ<span className="submenu-indicator" /></Link></li>
                        </ul>
                        {localStorage.getItem("roleName") === "AGENT" ?
                            <ul className="nav-menu nav-menu-social align-to-right">
                                <li className="list-buttons me-2">
                                    <Link to={"/profile"} className="bg-primary"><i className="fa-regular fa-circle-user fs-6 me-2" />My Account</Link>
                                </li>
                            </ul> :
                            <ul className="nav-menu nav-menu-social align-to-right">
                                <li className="list-buttons me-2">
                                    <Link to={"/agentregistration"} className="bg-primary"><i className="fa-regular fa-circle-user fs-6 me-2" />Agent Registration</Link>
                                </li>
                            </ul>}
                    </div>
                </nav>
            </div>
        </div>
    );
}