import { Routes, Route } from 'react-router-dom';
import SearchFlight from './Flights/SearchFlight';
import SearchHotel from './Hotels/SearchHotel';
import SearchTour from './Tours/SearchTour';
import AboutUs from './Home/AboutUs';
import OneWay from './Flights/OneWay';
import OneWayFlight from './Flights/OneWayFlight';
import OneWayItinerary from './Flights/OneWayItinerary';
import RoundTrip from './Flights/RoundTrip';
import RoundTripFlight from './Flights/RoundTripFlight';
import RoundTripItinerary from './Flights/RoundTripItinerary';
import PrivacyPolicy from './Home/PrivacyPolicy';
import Terms from './Home/Terms';
import FAQ from './Home/FAQ';
import Contact from './Home/Contact';
import Logout from './Authentication/Logout';
import HotelList from './Hotels/HotelList';
import HotelDetails from './Hotels/HotelDetails';
import HotelGuestDetail from './Hotels/HotelGuestDetail';
import HotelBookingSuccess from './Hotels/HotelBookingSucces';
import ForgetPassword from './User/ForgetPassword';
import ResetPassword from './User/ResetPassword';
import Refund from './Home/Refund';
import OneWayReview from './Flights/OneWayReview';
import RoundTripReview from './Flights/RoundTripReview';
import HotelReview from './Hotels/HotelReview';
import Tour from './Tours/Tour';
import TourDetails from './Tours/TourDetails';
import AgentLogin from './Agents/AgentLogin';
import AgentRegistration from './Agents/AgentRegistration';
import AgentProfile from './Agents/AgentProfile';
import Wallet from './Agents/Wallet';
import AgentFlightBookings from './Agents/AgentFlightBookings';
import AgentFlightBookingDetails from './Agents/AgentFlightBookingDetails';
import AgentHotelBooking from './Agents/AgentHotelBooking';
import AgentHotelBookingDetails from './Agents/AgentHotelBookingDetails';
import AgentVisaApplications from './Agents/AgentVisaApplications';
import AgentVisaApplicationDetails from './Agents/AgentVisaApplicationDetails';
import FlightVoucher from './Flights/FlightVoucher';
import FlightInvoice from './Flights/FlightInvoice';
import AgentFlightInvoice from './Agents/AgentFlightInvoice';
import CustomerFlightInvoice from './Agents/CustomerFlightInvoice';
import FLightReport from './Reports/FlightReport';
import HotelReport from './Reports/HotelReport';
import CustomerHotelVoucher from './Agents/CustomerHotelVoucher';
import CompanyBankAccount from './Agents/CompanyBankAccount';
import ConfirmEmail from './User/ConfirmEmail';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AgentLogin />} />
        <Route path="/agentlogin" element={<AgentLogin />} />
        <Route path="/searchflights" element={<SearchFlight />} />
        <Route path="/oneway-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<OneWay />} />
        <Route path="/oneway-flight/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayFlight />} />
        <Route path="/oneway-review/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayReview />} />
        <Route path="/oneway-itinerary/:flightBookingId?" element={<OneWayItinerary />} />

        <Route path="/roundtrip-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTrip />} />
        <Route path="/roundtrip-flight/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripFlight />} />
        <Route path="/roundtrip-review/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripReview />} />
        <Route path="/roundtrip-itinerary/:flightBookingId?" element={<RoundTripItinerary />} />
        <Route path="/flight-voucher/:flightBookingId?" element={<FlightVoucher />} />
        <Route path="/flight-invoice/:flightBookingId?" element={<FlightInvoice />} />

        <Route path="/searchhotels" element={<SearchHotel />} />
        <Route path="/hotelslist" element={<HotelList />} />
        <Route path="/hoteldetails" element={<HotelDetails />} />
        <Route path="/hotelreview" element={<HotelReview />} />
        <Route path="/hotelguests" element={<HotelGuestDetail />} />
        <Route path="/hotelbookingsuccess/:hotelBookingId?" element={<HotelBookingSuccess />} />

        <Route path="/searchtours" element={<SearchTour />} />
        <Route path="/tours" element={<Tour />} />
        <Route path="/tourdetails/:tourId?" element={<TourDetails />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />     
        <Route path="/logout" element={<Logout />} />
        <Route path="/flight-report" element={<FLightReport />} />
        <Route path="/hotel-report" element={<HotelReport />} />

        <Route path="/agentregistration" element={<AgentRegistration />} />
        <Route path="/agent-profile" element={<AgentProfile />} />
        <Route path="/agent-wallet" element={<Wallet />} />
        <Route path="/agent-flight-bookings" element={<AgentFlightBookings />} />
        <Route path="/flight-bookings-details/:flightBookingId" element={<AgentFlightBookingDetails />} />
        <Route path="/flight-booking-invoice/:flightBookingId" element={<AgentFlightInvoice />} />
        <Route path="/flight-customer-invoice/:flightBookingId" element={<CustomerFlightInvoice />} />

        <Route path="/agent-hotel-bookings" element={<AgentHotelBooking />} />
        <Route path="/hotel-bookings-details/:hotelBookingId" element={<AgentHotelBookingDetails />} />
        <Route path="/customer-hotel-voucher/:hotelBookingId" element={<CustomerHotelVoucher />} />

        <Route path="/bankaccounts" element={<CompanyBankAccount />} />
        <Route path="/confirmemail" element={<ConfirmEmail />} />
      </Routes>
    </div>
  );
}

export default App;
