module.exports = {
  COMPANYNAME: 'LAND AIR TRAVELS PRIVATE LIMITED',
  COMPANYEMAIL: 'support@landairtravels.com',
  COMAPNYPHONE: '7995331116',
  COMPANYCITY: 'HYDERABAD',
  COMPANYGST: '36AAKCG8669C1Z8',
  CURRENCY: 'INR',
  APILOCALURL: 'https://localhost:7086/api/',
  APISERVERURL: 'https://landairapis.azurewebsites.net/api/',
  APIACTIVATEURL: 'https://landairapis.azurewebsites.net/api/',

  LOGINUSER: 'home/login',
  REGISTERUSER: 'home/registeruser',
  CHECKEMAIL: 'home/check-email',
  CONFIRMEMAIL: 'home/confirmemail',
  SENDRESETPASSWORD: 'home/sendresetlink',
  RESETPASSWORD: 'home/resetpassword',
  GETPROMOCODES:'promocode',
  GETPAYMENTGATEWAYS:'Payment',

  REGISTERAGENT: 'home/RegisterAgent',
  LOGINAGENT: 'home/AgentLogin',
  GETAGENTBYID: 'User/GetAgentById',
  UPDATEAGENT: 'User/UpdateAgent',
  GETAGENTCREDITBYAGENTS: 'Admin/GetAgentCredits',
  GETAGENTWALLETBYAGENTS: 'Admin/GetAgentWallets',
  GETAGENTCREDIT: 'Admin/GetAgentCredit',
  GETAGENTWALLET: 'Admin/GetAgentWallet',
  UPDATECREDIT: 'Admin/UpdateCredit',
  UPDATEWALLET: 'Admin/UpdateWallet',
  GETAGENTBYUSER: 'User/GetAgentByUserId',

  GETUSERBYID: 'User/GetById',
  UPDATEUSER: 'User',
  CHANGEPASSWORD: 'User/ChangePassword',
  GETALLSUPPLIERS: 'Supplier/GetAll',
  GETSUPPLIERS: 'Supplier',
  CREATESUPPLIER: 'Supplier/Create',
  UPDATESUPPLIER: 'Supplier',
  DELETESUPPLIER: 'Supplier',
  GETSUPPLIERBYID: 'Supplier/GetById',

  GETALLSUPPLIERCONTACTS: 'SupplierContact/GetAll',
  GETSUPPLIERCONTACTS: 'SupplierContact',
  CREATESUPPLIERCONTACT: 'SupplierContact/Create',
  UPDATESUPPLIERCONTACT: 'SupplierContact',
  DELETESUPPLIERCONTACT: 'SupplierContact',
  GETSUPPLIERCONTACTBYSUPPLIERID: 'SupplierContact/GetById',
  GETBYSUPPLIERID: 'SupplierContact/GetById',

  SEARCHHOLIDAYDESTINATIONS: 'Destination/Search',
  GETALLHOLIDAYDESTINATIONS: 'Destination/GetAll',
  GETHOLIDAYDESTINATIONS: 'Destination',
  CREATEHOLIDAYDESTINATION: 'Destination/Create',
  UPDATEHOLIDAYDESTINATION: 'Destination',
  DELETEHOLIDAYDESTINATION: 'Destination',
  GETHOLIDAYDESTINATIONBYID: 'Destination',

  GETALLCUSTOMERS: 'Customer/GetAll',
  GETCUSTOMERS: 'Customer',
  CREATECUSTOMER: 'Customer/Create',
  UPDATECUSTOMER: 'Customer',
  DELETECUSTOMER: 'Customer',
  GETCUSTOMERBYID: 'Customer',


  GETALLWEDDINGENQUIRYS: 'WeddingEnquiry/GetAll',
  GETWEDDINGENQUIRYS: 'WeddingEnquiry',
  CREATEWEDDINGENQUIRY: 'WeddingEnquiry/Create',
  UPDATEWEDDINGENQUIRY: 'WeddingEnquiry',
  DELETEWEDDINGENQUIRY: 'WeddingEnquiry',
  GETWEDDINGENQUIRYBYID: 'WeddingEnquiry',

  GETALLWEDDINGDESTINATIONS: 'WeddingDestination/GetAll',
  GETWEDDINGDESTINATIONS: 'WeddingDestination',
  CREATEWEDDINGDESTINATION: 'WeddingDestination/Create',
  UPDATEWEDDINGDESTINATION: 'WeddingDestination',
  DELETEWEDDINGDESTINATION: 'WeddingDestination',
  GETWEDDINGDESTINATIONBYID: 'WeddingDestination',

  GETWEDDINGCONTENTBYDESTINATION: 'WeddingContent/GetByDestination',

  GETALLHOLIDAYENQUIRYS: 'HolidayEnquiry/GetAll',
  GETHOLIDAYENQUIRYS: 'HolidayEnquiry',
  CREATEHOLIDAYENQUIRY: 'HolidayEnquiry/Create',
  UPDATEHOLIDAYENQUIRY: 'HolidayEnquiry',
  DELETEHOLIDAYENQUIRY: 'HolidayEnquiry',
  GETHOLIDAYENQUIRYBYID: 'HolidayEnquiry',

  GETALLPACKAGEENQUIRYS: 'PackageEnquiry/GetAll',
  GETPACKAGEENQUIRYS: 'PackageEnquiry',
  CREATEPACKAGEENQUIRY: 'PackageEnquiry/Create',
  UPDATEPACKAGEENQUIRY: 'PackageEnquiry',
  DELETEPACKAGEENQUIRY: 'PackageEnquiry',
  GETPACKAGEENQUIRYBYID: 'PackageEnquiry',

  GETALLTOURS: 'Tour/GetAll',
  GETTOURS: 'Tour',
  CREATETOUR: 'Tour/Create',
  UPDATETOUR: 'Tour',
  DELETETOUR: 'Tour',
  GETTOURBYID: 'Tour',
  GETTOURSBYDESTINATIONID: 'Tour/GetByDestinationId',
  GETTOURSBYDESTINATION: 'Tour/GetByDestination',

  GETALLHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYPACKAGEID: 'HolidayItinerary/GetByPackageId',
  CREATEHOLIDAYITINERARY: 'HolidayItinerary/Create',
  UPDATEHOLIDAYITINERARY: 'HolidayItinerary',
  DELETEHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYID: 'HolidayItinerary',

  GETVISACOUNTRIES: 'Visa/GetVisaCountries',
  SEARCHVISACOUNTRIES: 'Visa/SearchVisaCountries',
  GETBYVISASBYCOUNTRY: 'Visa/GetVisasByCountry',
  GETBYVISASBYCOUNTRYID: 'Visa/GetVisasByCountryId',
  GETVISABYID: 'Visa',

  GETVISAPRICE: 'VisaPrice',
  GETVISAPRICEBYCOUNTRY: 'VisaPrice/PublicGetByCountry',
  CREATEVISAPRICE: 'VisaPrice/Create',
  UPDATEVISAPRICE: 'VisaPrice',
  DELETEVISAPRICE: 'VisaPrice',
  GETVISAPRICEBYID: 'VisaPrice',


  GETBYVISAAPPLICANT: 'VisaApplicantDocument/VisaApplicant',
  CREATE: 'VisaApplicantDocument/Create',
  UPDATE: 'VisaApplicantDocument',
  DELETE: 'VisaApplicantDocument',
  GETBYID: 'VisaApplicantDocument',

  GETALLVISAREQUEST: 'VisaRequest/GetAll',
  GETVISAREQUEST: 'VisaRequest',
  CREATEVISAREQUEST: 'VisaRequest/Create',
  UPDATEVISAREQUEST: 'VisaRequest',
  DELETEVISAREQUEST: 'VisaRequest',
  GETVISAREQUESTBYID: 'VisaRequest',
  GETVISAREQUESTBYUSERID: 'VisaRequest/GetByUserId',

  GETALLVISAAPPLICANT: 'VisaApplicant/GetAll',
  GETALLVISAAPPLICANT: 'VisaApplicant/GetByVisaRequest',
  GETVISAAPPLICANT: 'VisaApplicant',
  CREATEVISAAPPLICANT: 'VisaApplicant/Create',
  UPDATEVISAAPPLICANT: 'VisaApplicant',
  DELETEVISAAPPLICANT: 'VisaApplicant',
  GETVISAAPPLICANTBYID: 'VisaApplicant',

  PAYMENTREQUESTORDER: 'Payment/Process',
  PAYMENTCOMPLETEORDER: 'Payment/ProcessCompleteOrder',

  CREATETRANSACTION: 'Transaction/Create',

  SEARCHFLIGHTS: "Flight/Search",
  REVIEWFLIGHT: "Flight/Review",
  BOOKFLIGHT: "Flight/Book",
  GETUSERFLIGHTS: "User/GetFlightBookings",
  GETAGENTFLIGHTS: "Flight/GetByAgentId",
  GETFLIGHTBOOKINGBYID: 'Flight/GetById',

  SEARCHHOTELS: "Hotel/Search",
  HOTELDETAILS: "Hotel/Details",
  HOTELREVIEW: "Hotel/Review",
  HOTELBOOK: "Hotel/Book",
  GETUSERHOTELS: "User/GetHotelBookings",
  GETHOTELBYID: "Hotel/GetById",

  GETFLIGHTREPORT:'User/FlightReport',
  GETHOTELREPORT:'User/HotelReport',
}