import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../Configuration/APIConfig';
import axios from "axios";
import auth from "../Authentication/Auth";
import HeaderHome from "../Layout/HeaderHome";
const initialAgentRegisterValues = {
    agentId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    agencyName: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    location: "",
    gstNo: "",
    creditBalance: 0,
    flightCommission: 0,
    hotelCommission: 0,
    isActive: false,
    password: "",
    creditStatus: false,
    availableCredit: 0,
    availableTopUp: 0,
    pendingPayment: 0
};
export default function AgentRegistration(props) {
    const navigate = useNavigate();
    const [agentRegisterValues, setAgentRegisterValues] = useState(initialAgentRegisterValues);
    const [errors, setErrors] = useState({});
    const [registerErrors, setRegisterErrors] = useState({});
    const [btnRegisterSubmit, setBtnRegisterSubmit] = useState(true);
    const [registerSuccess, setRegisterSuccess] = useState("");
    const [registerErrorStatus, setRegisterErrorStatus] = useState("");
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const applicationAPI = (url = config.APIACTIVATEURL + config.LOGINAGENT) => {
        return {
            agentlogin: (newRecord) => axios.post(url, newRecord),
            agentregister: (newRecord) => axios.post(config.APIACTIVATEURL + config.REGISTERAGENT, newRecord)
        };
    };
    const handleAgentRegisterInputChange = (e) => {
        const { name, value } = e.target;
        setAgentRegisterValues({
            ...agentRegisterValues,
            [name]: value,
        });
    };
    const agentRegisterValidate = () => {
        let temp = {};
        temp.name = agentRegisterValues.name == '' ? false : true;
        temp.email = agentRegisterValues.email == '' ? false : true;
        temp.password = agentRegisterValues.password == '' ? false : true;
        temp.agencyName = agentRegisterValues.agencyName == '' ? false : true;
        temp.phoneNumber = agentRegisterValues.phoneNumber == '' ? false : true;
        setRegisterErrors(temp);
        return Object.values(temp).every((x) => x == true);
    };
    const handleAgentRegisterSubmit = (e) => {
        e.preventDefault();
        if (agentRegisterValidate()) {
            try {
                setBtnRegisterSubmit(false);
                const formData = new FormData()
                formData.append("email", agentRegisterValues.email)
                formData.append("password", agentRegisterValues.password)
                formData.append("phoneNumber", agentRegisterValues.phoneNumber)
                formData.append("name", agentRegisterValues.name)
                formData.append("roleName", agentRegisterValues.roleName)
                formData.append("domainURL", window.location.origin)
                formData.append("isActive", false)
                formData.append("agencyName", agentRegisterValues.agencyName)
                formData.append("city", agentRegisterValues.city)
                formData.append("address", agentRegisterValues.address)
                formData.append("gstno", agentRegisterValues.gstno)
                formData.append("pendingPayment", agentRegisterValues.pendingPayment)
                formData.append("availableTopUp", agentRegisterValues.availableTopUp)
                formData.append("availableCredit", agentRegisterValues.availableCredit)
                formData.append("creditStatus", agentRegisterValues.creditStatus)
                formData.append("flightCommission", agentRegisterValues.flightCommission)
                formData.append("hotelCommission", agentRegisterValues.hotelCommission)
                applicationAPI().agentregister(formData)
                    .then((res) => {
                        console.log(res)
                        if (res.data.response.succeeded === true) {
                            setBtnRegisterSubmit(true);
                            resetForm();
                            setRegisterSuccess(res.data.response.message)
                            setRegisterErrorStatus('')
                        } else {
                            setRegisterErrorStatus(res.data.response.message);
                            setRegisterSuccess('')
                            setBtnRegisterSubmit(true);
                        }
                    })
                    .catch(function (error) {
                        setRegisterErrorStatus(error);
                        setRegisterSuccess('')
                        setBtnRegisterSubmit(true);
                    });
            } catch (err) {
                setBtnRegisterSubmit(true);
                setRegisterSuccess('')
                setRegisterErrorStatus('Please check the mandatory fields');
            }
        } else {
            setBtnRegisterSubmit(true);
            setRegisterErrorStatus('Please check the mandatory fields');
            setRegisterSuccess('')
        }
    };
    const applyRegisterErrorClass = (field) => field in registerErrors && registerErrors[field] == false ? ' form-control-danger' : '';
    const resetForm = () => {
        setAgentRegisterValues(initialAgentRegisterValues)
    }
    return (
        <div id="main-wrapper">
            <HeaderHome></HeaderHome>
            <div className="clearfix"></div>
            {/* <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center m-auto">
                        <div className="col-12">
                            <div className="bg-mode shadow rounded-3 overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                        <div className="p-3 p-lg-5">
                                            <img src="/assets/img/login.jpg" className="img-fluid" alt />
                                        </div>
                                        <div className="vr opacity-1 d-none d-lg-block" />
                                    </div>
                                    <div className="col-lg-6 order-1">
                                        <div className="p-3 p-sm-4 p-md-5">
                                            <h1 className="mb-2 fs-2">Agent Registration!</h1>
                                            {registerSuccess !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {registerSuccess}. We will intimate once account is approved </div> : ""}
                                                {registerErrorStatus !== "" ? <div class="alert alert-danger shadow-md"> <i class="fas fa-check-circle"></i> {registerErrorStatus} </div> : ""}
                                                <form onSubmit={handleAgentRegisterSubmit} autoComplete="off" noValidate>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('agencyName')} name="agencyName" type="text" value={agentRegisterValues.agencyName} onChange={handleAgentRegisterInputChange} placeholder="Enter Agency Name" />
                                                                {registerErrors.agencyName === false ? (<div className="validationerror"> Please enter agencyName </div>) : ('')}
                                                                <label>Agency Name</label>
                                                            </div></div>
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('name')} name="name" type="text" value={agentRegisterValues.name} onChange={handleAgentRegisterInputChange} placeholder="Enter Name" />
                                                                {registerErrors.name === false ? (<div className="validationerror"> Please enter name </div>) : ('')}
                                                                <label>Contact Name</label>
                                                            </div></div>
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('email')} name="email" type="text" value={agentRegisterValues.email} onChange={handleAgentRegisterInputChange} placeholder="Enter Email" />
                                                                {registerErrors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                                                <label>Email</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('phoneNumber')} name="phoneNumber" type="text" value={agentRegisterValues.phoneNumber} onChange={handleAgentRegisterInputChange} placeholder="Enter Phone Number" />
                                                                {registerErrors.phoneNumber === false ? (<div className="validationerror"> Please enter phoneNumber </div>) : ('')}
                                                                <label>Phone Number</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('city')} name="city" type="text" value={agentRegisterValues.city} onChange={handleAgentRegisterInputChange} placeholder="Enter City" />
                                                                {registerErrors.city === false ? (<div className="validationerror"> Please enter city </div>) : ('')}
                                                                <label>City</label>
                                                            </div></div>
                                                        <div className="col-lg-6">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('password')} name="password" type="password" value={agentRegisterValues.password} onChange={handleAgentRegisterInputChange} placeholder="Enter Password" />
                                                                {registerErrors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                                                <label>Password</label>
                                                            </div></div>
                                                        <div className="col-lg-812">
                                                            <div className="form-floating mb-4">
                                                                <input className={'form-control' + applyRegisterErrorClass('address')} name="address" type="text" value={agentRegisterValues.address} onChange={handleAgentRegisterInputChange} placeholder="Enter Name" />
                                                                {registerErrors.address === false ? (<div className="validationerror"> Please enter address </div>) : ('')}
                                                                <label>Address</label>
                                                            </div></div>
                                                    </div>
                                                    <div className="form-group">
                                                        {btnRegisterSubmit === true ? (
                                                            <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Register</button>
                                                        ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                                                    </div>
                                                </form>
                                            <p className="mb-0">Already Registered ? <Link to={"/agentlogin"}className="fw-medium text-primary"> Login</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="container mt-5">
                <div className="row align-items-center">
                    {/* Left Section */}
                    <div className="col-md-6">
                        <div className="main-banner">
                            <h1>Empowering the Future of Travel Booking</h1>
                            <p>One stop solution for your travel business</p>
                            <div class="vector-images">
                                <span><i className="fa fa-plane" /></span>
                                <span><i className="fa fa-building" /></span>
                                <span><i className="fa fa-umbrella-beach" /></span>
                            </div>
                        </div>
                    </div>
                    {/* Right Section */}
                    <div className="col-md-5 offset-md-1">
                        <div className="login-card">
                            <h5 className="mb-2 fs-2">AGENT REGISTRATION</h5>
                            {registerSuccess !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {registerSuccess}. We will intimate once account is approved </div> : ""}
                            {registerErrorStatus !== "" ? <div class="alert alert-danger shadow-md"> <i class="fas fa-check-circle"></i> {registerErrorStatus} </div> : ""}
                            <form onSubmit={handleAgentRegisterSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('agencyName')} name="agencyName" type="text" value={agentRegisterValues.agencyName} onChange={handleAgentRegisterInputChange} placeholder="Enter Agency Name" />
                                                {registerErrors.agencyName === false ? (<div className="validationerror"> Please enter agencyName </div>) : ('')}
                                                <label>Agency Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('name')} name="name" type="text" value={agentRegisterValues.name} onChange={handleAgentRegisterInputChange} placeholder="Enter Name" />
                                                {registerErrors.name === false ? (<div className="validationerror"> Please enter name </div>) : ('')}
                                                <label>Contact Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('email')} name="email" type="text" value={agentRegisterValues.email} onChange={handleAgentRegisterInputChange} placeholder="Enter Email" />
                                                {registerErrors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                                <label>Email</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('phoneNumber')} name="phoneNumber" type="text" value={agentRegisterValues.phoneNumber} onChange={handleAgentRegisterInputChange} placeholder="Enter Phone Number" />
                                                {registerErrors.phoneNumber === false ? (<div className="validationerror"> Please enter phoneNumber </div>) : ('')}
                                                <label>Phone Number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('city')} name="city" type="text" value={agentRegisterValues.city} onChange={handleAgentRegisterInputChange} placeholder="Enter City" />
                                                {registerErrors.city === false ? (<div className="validationerror"> Please enter city </div>) : ('')}
                                                <label>City</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('password')} name="password" type="password" value={agentRegisterValues.password} onChange={handleAgentRegisterInputChange} placeholder="Enter Password" />
                                                {registerErrors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                                <label>Password (*min 9)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3 position-relative">
                                            <div className="form-floating mb-4">
                                                <input className={'form-control' + applyRegisterErrorClass('address')} name="address" type="text" value={agentRegisterValues.address} onChange={handleAgentRegisterInputChange} placeholder="Enter Address" />
                                                {registerErrors.address === false ? (<div className="validationerror"> Please enter address </div>) : ('')}
                                                <label>Address</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {btnRegisterSubmit === true ? (
                                            <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Register</button>
                                        ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                                    </div>
                                </div>
                            </form>
                            <p className="mt-3 text-center">
                                Already Registered ? <Link to={"/agentlogin"} className="fw-medium text-primary"> Login</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}