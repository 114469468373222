import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { handleSuccess, handleError } from "../Layout/CustomAlerts";
import { useNavigate } from "react-router-dom";
import Agenttab from "./AgentTab";
import AgentMobileTab from "./AgentMobileTab";
export default function AgentProfile() {
    const navigate = useNavigate();
    const initialFieldValues = {
        agentId: "00000000-0000-0000-0000-000000000000",
        userId: "00000000-0000-0000-0000-000000000000",
        agencyName: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        location: "",
        gstNo: "",
        creditBalance: 0,
        flightCommission: 0,
        hotelCommission: 0,
        status: "PENDING",
        password: "Test@123",
        creditStatus: true
    };
    const initialPasswordFieldValues = {
        id: "00000000-0000-0000-0000-000000000000",
        oldPassword: "",
        newPassword: ""
    };
    const [values, setValues] = useState(initialFieldValues);
    const [passwordValues, setPasswordValues] = useState(initialPasswordFieldValues);
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handlePasswordInputChange = (e) => {
        const { name, value } = e.target;
        setPasswordValues({
            ...passwordValues,
            [name]: value,
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const validatePassword = () => {
        let temp = {};
        temp.oldPassword = passwordValues.oldPassword === "" ? false : true;
        temp.newPassword = passwordValues.newPassword === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("agentId", values.agentId)
            formData.append("userId", values.userId)
            formData.append("name", values.name)
            formData.append("agencyName", values.agencyName)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("city", values.city)
            formData.append("address", values.address)
            formData.append("status", values.status)
            formData.append("location", values.location)
            formData.append("gstNo", values.gstNo)
            formData.append("password", values.password)
            formData.append("creditBalance", values.creditBalance)
            formData.append("creditStatus", values.creditStatus)
            formData.append("flightCommission", values.flightCommission)
            formData.append("hotelCommission", values.hotelCommission)
            addOrEdit(formData);
        }
    };
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (validatePassword()) {
            changePassword();
        }
    };
    const applicationAPI = () => {
        return {
            update: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.UPDATEAGENT, newrecord, { ...headerconfig }),
            change: (crecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CHANGEPASSWORD, crecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .update(formData)
            .then((res) => {
                if (res.data.response.succeeded === true) {
                    handleSuccess("Record updated successfully");
                    GetUserData();
                }
                else {
                    handleError(res.data.response.message);
                }
            });
    };
    const changePassword = () => {
        const formData = new FormData()
        formData.append("newPassword", passwordValues.newPassword)
        formData.append("userId", localStorage.getItem('userId'))
        formData.append("oldPassword", passwordValues.oldPassword)
        applicationAPI()
            .change(formData)
            .then((res) => {
                if (res.data.response.succeeded === true) {
                    handleSuccess(res.data.response.message);
                }
                else {
                    handleError(res.data.response.message);
                }
            });
    };
    const GetUserData = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETAGENTBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                setValues(response.data.response.data);
            });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetUserData();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <AgentMobileTab></AgentMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-file-invoice me-2" />Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('agencyName')} name="agencyName" type="text" value={values.agencyName} onChange={handleInputChange} placeholder="Enter Agency Name" />
                                                        {errors.agencyName === false ? (<div className="validationerror"> Please enter agencyName </div>) : ('')}
                                                        <label>Agency Name</label>
                                                    </div></div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('name')} name="name" type="text" value={values.name} onChange={handleInputChange} placeholder="Enter Name" />
                                                        {errors.name === false ? (<div className="validationerror"> Please enter name </div>) : ('')}
                                                        <label>Contact Name</label>
                                                    </div></div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={values.email} onChange={handleInputChange} placeholder="Enter Email" />
                                                        {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('phoneNumber')} name="phoneNumber" type="text" value={values.phoneNumber} onChange={handleInputChange} placeholder="Enter Phone Number" />
                                                        {errors.phoneNumber === false ? (<div className="validationerror"> Please enter phoneNumber </div>) : ('')}
                                                        <label>Phone Number</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input onInput={toInputUppercase} className={'form-control' + applyErrorClass('gstNo')} name="gstNo" type="text" value={values.gstNo} onChange={handleInputChange} placeholder="Enter GST Number" />
                                                        {errors.gstNo === false ? (<div className="validationerror"> Please enter GST Number </div>) : ('')}
                                                        <label>GST Number</label>
                                                    </div></div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('city')} name="city" type="text" value={values.city} onChange={handleInputChange} placeholder="Enter City" />
                                                        {errors.city === false ? (<div className="validationerror"> Please enter city </div>) : ('')}
                                                        <label>City</label>
                                                    </div></div>
                                                <div className="col-lg-12">
                                                    <div className="form-floating mb-4">
                                                        <input className={'form-control' + applyErrorClass('address')} name="address" type="text" value={values.address} onChange={handleInputChange} placeholder="Enter Name" />
                                                        {errors.address === false ? (<div className="validationerror"> Please enter address </div>) : ('')}
                                                        <label>Address</label>
                                                    </div></div>
                                            </div>
                                            <div className="row align-items-center justify-content-start">
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-md btn-primary mb-0">Update Profile</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-lock me-2" />Update Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handlePasswordSubmit} autoComplete="off" noValidate>
                                            <div className="row align-items-center justify-content-start">
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Old Password</label>
                                                        <input type="password" value={passwordValues.oldPassword} name="oldPassword" onChange={handlePasswordInputChange} className={"form-control" + applyErrorClass('oldPassword')} placeholder="*************" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">New Password</label>
                                                        <input type="password" value={passwordValues.newPassword} name="newPassword" onChange={handlePasswordInputChange} className={"form-control" + applyErrorClass('newPassword')} placeholder="*************" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-md btn-primary mb-0">Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}